<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="仓库">
					<c-select name="warehouse_id" options="WAREHOUSE_LIST" ></c-select>
				</m-search-item>
				<m-search-item label="出入库类型">
					<c-checkbox-group name="type" :options="typeOptions"></c-checkbox-group>
				</m-search-item>
				<m-search-item label="关联业务单号">
					<c-input name="work_order_number"></c-input>
				</m-search-item>
				<m-search-item label="产品名称">
					<c-input name="product_name"></c-input>
				</m-search-item>
<!--				<m-search-item label="产品编码">
					<c-input name="product_code"></c-input>
				</m-search-item>-->
				<m-search-item label="产品颜色">
					<c-input name="product_spec"></c-input>
				</m-search-item>
				<m-search-item label="业务类型" style="width:500px">
					<c-checkbox-group name="work_type" :options="workTypeOptions"></c-checkbox-group>
				</m-search-item>
				<m-search-item label="操作时间" colspan="2">
					<c-datetime start-name="start_date" end-name="end_date" range></c-datetime>
				</m-search-item>
			</m-search>

			<c-table ref="table" height="grow">
				<c-table-column
					label="仓库"
					width="80"
					name="warehouse_name"
				>
				</c-table-column>
				<c-table-column
					label="业务类型"
					width="80"
					name="work_type"
				>
					<template #default="{data}">
						<span v-if="data.work_type == 1">报工</span>
						<span v-if="data.work_type == 2">发货</span>
						<span v-if="data.work_type == 3">入库单</span>
						<span v-if="data.work_type == 4">出库单</span>
						<span v-if="data.work_type == 5">盘点单</span>
					</template>
				</c-table-column>
				<c-table-column
					label="关联业务单号"
					width="120"
					name="work_order_number"
				>
				</c-table-column>
				<c-table-column
					label="出入库类型"
					width="90"
					name="type"
				>
					<template #default="{data}">
						<span v-if="data.type == 1" style="color:red;">出库</span>
						<span v-if="data.type == 2" style="color:green;">入库</span>
					</template>
				</c-table-column>
				<c-table-column
					label="产品"
					width="200"
					name="product_name"
				>
					<template #default="{data}">
						<span>{{data.product_name}}</span>
						<span v-if="data.product_code">【{{data.product_code}}】</span>
					</template>
				</c-table-column>
				<c-table-column
					label="产品颜色"
					width="80"
					name="product_spec"
				>
					<template #default="{data}">
						<span v-if="data.product_spec">{{data.product_spec}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>
				<c-table-column
					label="变动数量"
					width="70"
					name="product_count"
				>
				</c-table-column>
				<c-table-column
					label="操作说明"
					width="350"
					name="remark"
				>
				</c-table-column>
				<c-table-column
					label="操作人"
					width="100"
					name="create_user_realname"
				>
				</c-table-column>
				<c-table-column
					label="操作时间"
					width="140"
					name="create_date"
				>
				</c-table-column>
				
			</c-table>
		</module>
  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'
import {asyncMapState} from '@/store'

export default {
	name: 'm_data_in_out',
	components: {
    
	},
	data() {
		return {
			product_name: '',
			typeOptions: [{
				name: '出库',
				value: 1
			}, {
				name: '入库',
				value: 2
			}],
			workTypeOptions: [{
				name: '报工',
				value: 1
			}, {
				name: '发货',
				value: 2
			}, {
				name: '入库单',
				value: 3
			}, {
				name: '出库单',
				value: 4
			}, {
				name: '盘点单',
				value: 5
			}]
		}
	},
	computed: {
		...asyncMapState(['DIC_INSTORAGE_SOURCE'])
	},
	mounted() {
		this.$refs.search.search();
	},
	methods: {
		//搜索
		searchHandle(data) {
			this.$refs.table.load({
				url: '/data/inventory/record/list',
				data
			});
		}
  }
}
</script>